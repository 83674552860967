import {useState, useEffect} from "react";
import Head from 'next/head'
import supportsWebP from 'supports-webp';
import AOS from "aos";

export default function MainLayout(
    {
        children,
        title,
        alternate,
        description,
        keywords,
        page,
        loaded
    }) {

    const [origin, setOrigin] = useState('')
    const [host, setHost] = useState('')

    useEffect(() => {

        setOrigin(window.location.origin)
        setHost(window.location.host)

        supportsWebP.then(supported => {
            if (supported) {
                document.body.classList.add('webp')
            }
        });
    }, [])

    useEffect(() => {
        if(loaded) {
            AOS.init({
                once: true
            });
        }
    }, [loaded])

    return (
        <>
            <Head>
                <title>{title}</title>
                <link rel="icon" href={`/img/icons/favicon.png`}/>
                <link rel="apple-touch-icon" sizes="180x180" href={`/img/icons/apple-touch-icon.png`}/>
                <link rel="icon" type="image/png" sizes="32x32" href={`/img/icons/favicon-32x32.png`}/>
                <link rel="icon" type="image/png" sizes="16x16" href={`/img/icons/favicon-16x16.png`}/>
                <link rel="mask-icon" href={`/img/icons/safari-pinned-tab.svg" color="#fc5a57`}/>
                <meta name="msapplication-TileColor" content="#da532c"/>
                <meta name="theme-color" content="#ffffff"/>
                <meta name="description" content={description}/>
                <meta name="keywords" content={keywords}/>
                <link rel="canonical" href={origin}/>
                <link rel="alternate" hrefLang="en"
                      href={alternate ? `${origin}/${alternate}` : `${origin}`}/>
                <link rel="alternate" hrefLang="ar"
                      href={alternate ? `${origin}/ar/${alternate}` : `${origin}/ar`}/>
                <link rel="alternate" hrefLang="de"
                      href={alternate ? `${origin}/de/${alternate}` : `${origin}/de`}/>
                <link rel="alternate" hrefLang="fr"
                      href={alternate ? `${origin}/fr/${alternate}` : `${origin}/fr`}/>
                {/*<link rel="alternate" hrefLang="hi"*/}
                {/*      href={alternate ? `${origin}/id/${alternate}` : `${origin}/id`}/>*/}
                <link rel="alternate" hrefLang="id"
                      href={alternate ? `${origin}/hi/${alternate}` : `${origin}/hi`}/>
                <link rel="alternate" hrefLang="it"
                      href={alternate ? `${origin}/it/${alternate}` : `${origin}/it`}/>
                <link rel="alternate" hrefLang="ja"
                      href={alternate ? `${origin}/ja/${alternate}` : `${origin}/ja`}/>
                <link rel="alternate" hrefLang="ko"
                      href={alternate ? `${origin}/ko/${alternate}` : `${origin}/ko`}/>
                <link rel="alternate" hrefLang="pl"
                      href={alternate ? `${origin}/pl/${alternate}` : `${origin}/pl`}/>
                <link rel="alternate" hrefLang="pt"
                      href={alternate ? `${origin}/pt/${alternate}` : `${origin}/pt`}/>
                <link rel="alternate" hrefLang="ru"
                      href={alternate ? `${origin}/ru/${alternate}` : `${origin}/ru`}/>
                {/*<link rel="alternate" hrefLang="th"*/}
                {/*      href={alternate ? `${origin}/th/${alternate}` : `${origin}/th`}/>*/}
                {/*<link rel="alternate" hrefLang="vi"*/}
                {/*      href={alternate ? `${origin}/vi/${alternate}` : `${origin}/vi`}/>*/}
                <link rel="alternate" hrefLang="zh"
                      href={alternate ? `${origin}/zh/${alternate}` : `${origin}/zh`}/>
                <link rel="alternate" hrefLang="x-default"
                      href={alternate ? `${origin}/${alternate}` : `${origin}`}/>
                <meta property="og:url" content={origin}/>
                <meta property="og:type" content="website"/>
                <meta property="og:title" content=''/>
                <meta property="og:description" content=''/>
                <meta property="og:image" content={`${origin}/img/main/og.png`}/>

                <meta name="twitter:card" content="summary_large_image"/>
                <meta property="twitter:domain" content={host}/>
                <meta property="twitter:url" content={host}/>
                <meta name="twitter:title" content=''/>
                <meta name="twitter:description" content=''/>
                <meta name="twitter:image" content={`${origin}/img/main/og.png`}/>
            </Head>
            <div className={page}>
                {children}
            </div>
        </>
    )
}