import React, {useEffect, useState, useRef} from 'react';
import Link from "next/link";
import ChangeLanguage from "./changeLanguage";
import MainButton from "../buttons/MainButton";
import process from "next/dist/build/webpack/loaders/resolve-url-loader/lib/postcss";

let scrollTop;
let prevDepth;

const Header = ({t}) => {

    const ref = useRef(null);
    const menu = t('common:menu', {}, {returnObjects: true})
    const banner = t('home:banner', {}, {returnObjects: true});
    const locales = t('common:locales', {}, {returnObjects: true})
    const [isMenuVisible, setIsMenuVisible] = useState(false)

    const [scrolled, setScrolled] = useState(false);
    const [blur, setBlur] = useState(false);

    // const handleScroll = () => {
    //
    //     scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    //     if (scrollTop < 10) {
    //         setBlur(false)
    //     }
    //
    //     if (scrollTop >= 11) {
    //         if (prevDepth < scrollTop) {
    //             setScrolled(true)
    //
    //         } else {
    //             setScrolled(false)
    //             setBlur(true)
    //         }
    //     }
    //     prevDepth = window.pageYOffset || document.documentElement.scrollTop;
    // }

    // useEffect(() => {
    //     if (window.pageYOffset || document.documentElement.scrollTop > 11) {
    //         setScrolled(true)
    //     } else {
    //         setScrolled(false)
    //     }
    //     setTimeout(() => {
    //         window.addEventListener('scroll', handleScroll)
    //     }, 100)
    //
    //     return () => {
    //         window.removeEventListener('scroll', handleScroll)
    //     }
    // }, [])

    const toggleMenu = () => {
        setIsMenuVisible(!isMenuVisible);
    }

    useEffect(() => {
        isMenuVisible ? document.body.classList.add('is_menu_visible') : document.body.classList.remove('is_menu_visible')
    }, [isMenuVisible])

    useEffect(() => {
        if (isMenuVisible) {
            const handleOutSideClick = (event) => {

                if (isMenuVisible && !ref.current?.contains(event.target)) {
                    toggleMenu()
                }
            };

            window.addEventListener("mousedown", handleOutSideClick);

            return () => {
                window.removeEventListener("mousedown", handleOutSideClick);
            };
        }
    }, [isMenuVisible, ref]);

    const MenuItem = ({item, i}) => {
        return <li className={`main_nav_item`}
                   data-aos="fade-up" data-aos-duration="800" data-aos-easing="ease-out-quad"
                   data-aos-delay={100 * (i+1)}
        >
            <Link
                prefetch={false}
                scroll={false}
                className='nav_left_list_inner_link'
                href={item.url === 'whitepaper' ? process.env.NEXT_PUBLIC_WHITEPAPER_URL : item.url}
                target={item.target}
            >
                {item.title}
            </Link>

        </li>
    }

    return (
        <header className={`header ${scrolled ? ' scrolled' : ''} ${blur ? 'blur' : ''}`}>
            <div className='top_line'>
                <div className="container">
                    <div className='top_line_text'>
                        <span>{banner.progress.promo_1} </span>
                        <span className='top_line_text_bold'> MPEPE30 </span>
                        <span>{banner.progress.promo_2} </span>
                        <span className='top_line_text_green'>30% {banner.progress.promo_3}</span>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className='header_wrapper'>
                    <Link
                        className='nav_logo'
                        prefetch={false}
                        href={'/'}
                    >
                        <img src={'/logo.png'} alt={'Buy $MPEPE'}/>
                    </Link>
                    <nav className='nav_list_main nav_list'>
                        <ul className='nav_list_main_items'>
                            {menu.list.map((item, i) => {
                                return <MenuItem
                                    i={i}
                                    key={item.title}
                                    item={item}
                                />
                            })}
                        </ul>
                    </nav>
                    <svg
                        onClick={toggleMenu}
                        className='nav_burger' xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                        viewBox="0 0 24 24" fill="none">
                        <path d="M3 18V16H21V18H3ZM3 13V11H21V13H3ZM3 8V6H21V8H3Z" fill="#fff"/>
                    </svg>
                    <nav className='nav_list_add nav_list'>
                        <ul className='nav_list_add_items'>
                            <li className='lang phone_hidden nav_item'
                                data-aos="fade-up" data-aos-duration="800" data-aos-easing="ease-out-quad"
                                data-aos-delay={100 * 7}
                            >
                                <ChangeLanguage
                                    locales={locales}
                                />
                            </li>
                            <li className='mobile_hidden nav_item'
                                data-aos="fade-up" data-aos-duration="800" data-aos-easing="ease-out-quad"
                                data-aos-delay={100 * 8}
                            >
                                <Link
                                    className='main_nav_item_text'
                                    prefetch={false}
                                    href={process.env.NEXT_PUBLIC_APP_URL + '/sign-in'}
                                >
                                    {menu.sign_in}
                                </Link>
                            </li>
                            <li
                                className='nav_item'
                                data-aos="fade-up" data-aos-duration="800" data-aos-easing="ease-out-quad"
                                data-aos-delay={100 * 9}
                            >
                                <Link
                                    className='nav_buy_button'
                                    prefetch={false}
                                    href={process.env.NEXT_PUBLIC_APP_URL + '/sign-up'}
                                >
                                    <MainButton
                                        text={menu.sign_up}
                                    />
                                </Link>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </header>
    );
};

export default Header;