import React from 'react';
import Link from "next/link";
import process from "next/dist/build/webpack/loaders/resolve-url-loader/lib/postcss";

const Footer = ({t}) => {

    const footer = t('common:footer', {}, {returnObjects: true});

    const MainItem = ({item}) => {
        return <li className='list_item' key={item.url}>
            <Link
                target={item.target}
                className='list_item'
                prefetch={false}
                href={item.url === 'whitepapper' ? process.env.NEXT_PUBLIC_WHITEPAPER_URL : item.url === 'join' ? process.env.NEXT_PUBLIC_APP_URL + '/sign-up' : item.url}
            >
                {item.title}
            </Link>
        </li>

    }

    const BottomItem = ({item}) => {
        return <li className='list_item'>
            <Link
                className='list_item_link'
                prefetch={false}
                href={item.url}
                target={item.target}
            >
                {item.title}
            </Link>
        </li>
    }

    const SocialItem = ({item}) => {
        return <li
            className={'social_item'}
        >
            <Link
                key={item.title}
                target={'_blank'}
                className='social_item_link'
                href={item.title === 'x' ? process.env.NEXT_PUBLIC_X_URL : process.env.NEXT_PUBLIC_TELEGRAM_URL}
                aria-label={item.title}
            >
                <img width={28} height={28} src={`/img/pages/home/welcome/${item.title}.svg`}
                     alt={`${item.title} icon`}/>
            </Link>
        </li>
    }


    return (
        <footer className='footer'>
            <div className="container">
                <div className="footer_main">
                    <div className="main_nav">
                        <h3 className={'footer_block_title'}>{footer.nav.title}</h3>
                        <nav>
                            <ul className='footer_main_item'>
                                {
                                    footer.nav.items.map(item => {
                                        return <MainItem
                                            key={item.title}
                                            item={item}
                                        />
                                    })
                                }
                            </ul>
                        </nav>
                    </div>
                    <div className='footer_logo'>
                        <picture>
                            <source
                                media="(max-width: 768px)"
                                srcSet={`/img/main/footer_logo.webp`}
                                type="image/webp"
                            />
                            <source
                                media="(min-width: 769px)"
                                srcSet={`/img/main/footer_logo_x2.webp`}
                                type="image/webp"
                            />
                            <source
                                media="(max-width: 768px)"
                                srcSet={`/img/main/footer_logo.png`}
                            />
                            <source
                                media="(min-width: 769px)"
                                srcSet={`/img/main/footer_logo_x2.png`}
                            />
                            <img
                                data-aos="fade-up" data-aos-duration="800" data-aos-easing="ease-out-quad"
                                data-aos-delay={100}
                                src={`/img/main/footer_logo.png`}
                                alt={'$MPEPE Token Presale'}
                                width={281}
                                height={335}
                                className='footer_logo_img'
                            />
                        </picture>
                    </div>
                    <div className="footer_info">
                        <h3 className={'footer_block_title'}>{footer.contact.title}</h3>
                        <ul className='footer_main_item'>
                            <li className="list_item">
                                <span style={{marginRight: '6px'}}>{footer.contact.info}</span>
                                <Link
                                    className='list_item_link'
                                    prefetch={false}
                                    href={'mailto:contact@mpeppe.io'}
                                >
                                    contact@mpeppe.io
                                </Link>
                            </li>
                            <li className="list_item">
                                <span style={{marginRight: '6px'}}>{footer.contact.help}</span>
                                <Link
                                    className='list_item_link'
                                    prefetch={false}
                                    href={'mailto:support@mpeppe.io'}
                                >
                                    support@mpeppe.io
                                </Link>
                            </li>
                        </ul>
                        <h3 className={'footer_block_title'}>{footer.social.title}</h3>
                        <ul className='footer_social'>
                            {footer.social.items.map(item => {
                                return <SocialItem
                                    key={item.title}
                                    item={item}
                                />
                            })}
                        </ul>
                    </div>
                </div>
                <ul className="footer_bottom">
                    {footer.bottom.map(el => {
                        return <BottomItem
                            key={el.title}
                            item={el}
                        />
                    })}
                </ul>
                <ul className='footer_disclaimer'>
                    {footer.disclaimer.map(el => {
                        return <li
                        key={el.key}
                            className='footer_disclaimer_item'
                        >
                            <div className={'item_wrapper'}>
                                <div className={'item_wrapper_key'}>{el.key}</div>
                                {el.text}
                            </div>
                        </li>
                    })}
                </ul>
            </div>
        </footer>
    );
}

export default Footer;