import React from 'react';

const MainButton = ({text, cta, style}) => {
    return (
        <button
            style={style}
            onClick={cta ? cta : void(0)}
            className={`main_button`}
        >
            {text}
        </button>
    );
};

export default MainButton;